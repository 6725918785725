import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts'
import styled from '@emotion/styled'
import { theme } from '../../assets/theme'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import 'moment/locale/ru'
import { Container } from '../../components'
moment.locale('ru')

const Timetable = ({ data, pageContext: context, location }) => {
  return (
    <Layout
      location={location}
      data={data.strapiTimetable}
      meta={context?.meta}
      menu={context?.menu}
      locale={context?.locale}>
      <SContainer>
        <ReactMarkdown>{data.strapiTimetable?.body}</ReactMarkdown>
        <Table>
          {data.strapiTimetable?.timelists.map(item => (
            <TimeItem>
              <span>{moment(item.date).format('DD MMMM, dddd')}</span>
              <span>{item.description}</span>
              <span>{item.time?.slice(0, 5)}</span>
            </TimeItem>
          ))}
        </Table>
      </SContainer>
    </Layout>
  )
}

export const query = graphql`
  query TimetableTemplate($locale: String!) {
    strapiTimetable(locale: { eq: $locale }) {
      title
      body
      background {
        url
      }
      timelists {
        id
        time
        description
        date
      }
    }
  }
`

const SContainer = styled(Container)`
  flex-direction: column;
  padding: 4rem 0 !important;

  ${() => theme.mqMax('lg')} {
    padding: 2rem 10px !important;
  }

  h2 {
    font-size: 42px;
  }

  h3 {
    font-size: 32px;
  }
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const TimeItem = styled.div`
  display: flex;
  gap: 6rem;
  overflow: hidden;

  span {
    padding: 1rem;
    display: flex;
    align-items: center;
    flex: 1;

    &:first-child {
      max-width: 150px;
    }

    &:last-child {
      max-width: max-content;
      background: #cac297;
    }
  }

  &:nth-child(odd) {
    background: #f2f2f2;
  }

  ${() => theme.mqMax('lg')} {
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    background: #f2f2f2;
    span {
      width: 100%;
      padding: 1rem 0;
      &:first-child {
        max-width: 100%;
      }

      &:last-child {
        max-width: 100%;
        padding-left: 1rem;
      }
    }
  }
`

export default Timetable
